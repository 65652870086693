import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { accessPage } from "../CONSTANTS/CONSTANTS";

const TestHome = () => {
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [overlay, setOverlay] = useState(false);
  const navigate = useNavigate();

  const handleCodeVerification = async () => {
    if (code.length !== 5) {
      setMessage("Vennligst skiv inn en gyldig 5-sifret kode.");
      return;
    }

    try {
      const response = await fetch(
        `https://escapecitygames.pellenilsen.no/api/check-code/${code}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);

        const usageResponse = await fetch(
          `https://escapecitygames.pellenilsen.no/api/check-code-usage/${code}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (usageResponse.ok) {
          const usageData = await usageResponse.json();
          if (usageData.success) {
            setMessage(`Welcome, the code belongs to: ${data.customerName}`);
            navigate(`/game?code=${code}&location=${data.location}`);
          } else {
            setMessage(
              "Koden er allerede i bruk. Vennligst vent 2 minutter og prøv igjen."
            );
          }
        } else {
          const usageErrorData = await usageResponse.json();
          setMessage(usageErrorData.message || "Error checking code usage.");
        }
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || "Error verifying code.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Failed to connect to the server.");
    }
  };

  const handleOverlayClick = () => {
    setOverlay(!overlay);
  };

  return (
    <div className="m-0 p-0">
      {overlay && (
        <div className="fixed bg-white/80 z-[999] w-full h-full flex justify-center items-center">
          <div className="bg-white rounded-2xl p-10 relative">
            <button
              aria-label="Close overlay"
              className="absolute top-4 right-4 text-3xl text-red-700"
              onClick={handleOverlayClick}
            >
              X
            </button>
            <h1 className="text-2xl font-semibold font-primary mt-5">
              Skriv inn din kode:
            </h1>
            <p className="text-sm font-primary mt-5">
              Hvis du har mistet tilkobling, vennligst vent 2 minutter, og prøv
              igjen med samme kode.
            </p>
            <input
              type="text"
              className="block p-4 border w-full border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md shadow-sm mt-4"
              placeholder="Eks. 12345"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              className="mt-2 w-full px-4 py-3 bg-gradient-to-r from-[#AAC4FC] to-[#98D1AB] text-lg text-white font-button"
              onClick={handleCodeVerification}
            >
              Start spillet!
            </button>
            {message && <p className="mt-4 text-red-500">{message}</p>}
          </div>
        </div>
      )}

      <section id="hero">
        <div
          className="w-full flex justify-between"
          onClick={() => navigate("/")}
        >
          <img
            src="https://escapecitygame.no/content/confetti.png"
            alt="confetti"
            className="absolute w-full -z-10"
          />
          <div className="flex h-16">
            <img src="https://escapecitygame.no/content/logo.png" alt="logo" />
            <h1 className="my-auto h-min font-primary text-[16px] font-semibold uppercase">
              Escape City Game
            </h1>
          </div>
          <div>
            {/* <svg width="64" height="64" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="12" y1="25" x2="38" y2="25" stroke="#1D1D1D" strokeWidth="4" strokeLinecap="round"/>
                            <line x1="12" y1="16" x2="38" y2="16" stroke="#1D1D1D" strokeWidth="4" strokeLinecap="round"/>
                            <line x1="12" y1="34" x2="38" y2="34" stroke="#1D1D1D" strokeWidth="4" strokeLinecap="round"/>
                        </svg> */}
          </div>
        </div>
      </section>

      <div className="sm:flex">
        <section id="galleri" className="p-6 mt-24 flex justify-center">
          <img
            src="https://escapecitygame.no/content/galleri.png"
            alt="galleri"
            className="max-w-[70%]"
          />
        </section>

        <section id="intro" className="px-4 m-auto text-center">
          <h2 className="font-primary text-2xl">
            Opplev Gamlebyen Fredrikstad på en ny, morsom og historisk måte!
          </h2>
          <p className="font-primary mt-0 font-light mb-8">
            Prøv Escape the city der man får en Escaperoom opplevelse utendørs!
            Spill når du vil og med hvem du vil!
          </p>
          <label className="p-6 bg-blue-400 rounded-lg text-white font-bold">
            NYHET! VI HAR KOMMET TIL HAMAR!
          </label>{" "}
          <br />
          <button
            className="mt-9 px-4 py-3 bg-gradient-to-r from-[#AAC4FC] to-[#98D1AB] text-lg text-white font-button"
            onClick={() => navigate("/purchase")}
          >
            Spill nå
          </button>
          <div className="flex text-center w-full justify-center mt-2 mb-6">
            <button className="underline" onClick={handleOverlayClick}>
              Jeg har allerede kjøpt spill
            </button>
            <svg
              width="19"
              height="8"
              viewBox="0 0 19 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="my-auto ml-2"
            >
              <path
                d="M18.3536 4.35355C18.5488 4.15829 18.5488 3.84171 18.3536 3.64645L15.1716 0.464466C14.9763 0.269204 14.6597 0.269204 14.4645 0.464466C14.2692 0.659728 14.2692 0.976311 14.4645 1.17157L17.2929 4L14.4645 6.82843C14.2692 7.02369 14.2692 7.34027 14.4645 7.53553C14.6597 7.7308 14.9763 7.7308 15.1716 7.53553L18.3536 4.35355ZM0 4.5H18V3.5H0V4.5Z"
                fill="black"
              />
            </svg>
          </div>
          <Link to="/leaderboard" className="underline mt-6">
            Se leaderboard
          </Link>
        </section>
      </div>

      <section
        id="flykte"
        className="bg-[#F9F7F7] mt-12 p-6 sm:px-20 shadow-[inset_0_0_8px_rgba(0,0,0,0.6)] text-center"
      >
        <h2 className="font-primary text-2xl">
          Klarer dere å flykte fra den svenske okkupasjonen i 1814?
        </h2>
        <p className="font-primary mt-0 font-light mt-4">
          Dere skal hjelpe Kommandant Christian Hals å flykte fra svenskene og
          finne den hemmelige utgangen ut av byen. Svar på spm og Finn svar på
          gåter for å komme nærmere frihet! For hver oppgave dere klarer får
          dere en bokstav! Klarer dere alle oppgavene kan dere klare å tyde
          setningen dere skal frem til. Den hemmelige setningen sier dere hvor
          utgangen er.
        </p>
        <img
          src="https://escapecitygame.no/content/flykte.png"
          alt="funksjon"
          className="mt-4 mx-auto"
        />
        <p className="font-primary mt-0 font-light mt-4">
          Dere får 2 timer på dere å komme ut, eller så havner Kommandanten bak
          lås og slå… Tar dere utfordringen?
        </p>
      </section>

      <section id="funksjon" className="mt-12 text-center mb-20 sm:px-20">
        <h2 className="font-primary text-2xl">Praktisk info</h2>
        <ul className="text-left p-4 list-disc list-outside ml-5 mx-auto">
          <li>
            For at dere skal kunne spille må dere tillate stedstjenester slik at
            spillet ser hvor dere beveger dere.
          </li>
          <li>Anbefaler en gruppe på maks 4 pers per enhet.</li>
          <li>
            Pass på å ha strøm nok på telefonen, eller ha powerbank
            tilgjengelig.
          </li>
          <li>
            OBS! Klarer dere å hoppe ut av spillet, gå tom for strøm eller på en
            eller annen måte miste tilgang, så vil dere miste fremgangen deres.
          </li>
          <li>
            Tiden starter ikke å telle ned før dere har trykket på «Start spill»
            og etter 2 timer er gått vil det ikke lenger være mulig å bruke
            samme kode.{" "}
          </li>
        </ul>
        <img
          src="https://escapecitygame.no/content/funksjon.png"
          alt="funksjon"
          className="mt-4 mx-auto"
        />
      </section>

      <section
        id="kontakt"
        className="bg-[#F9F7F7] mt-12 p-6 shadow-[inset_0_0_8px_rgba(0,0,0,0.6)] text-center"
      >
        <h2 className="font-primary text-2xl">Ta kontakt</h2>
        <p className="font-primary mt-4 font-light">
          Hvis du har ett problem, eller noe du ville likt å ta opp med oss, ta
          kontakt via e-post.
        </p>
        <p className="font-primary mt-4 mb-4">
          Kundeservice:{" "}
          <a href="mailto:support@escapecitygame.no" className="underline">
            support@escapecitygame.no
          </a>
        </p>
        <Link className="font-primary text-blue-500 underline" to="/vilkår">
          Salgsvilkår og Betingelser
        </Link>
        <p className="font-primary mt-4">Org. nr: 933482847</p>
      </section>
    </div>
  );
};

export default TestHome;
